import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import loadingGif from '../../../assets/images/loader.gif';

import * as modalActions from "../../../store/actions/modal";
import ReactTooltip from "react-tooltip";
import * as actionTypes from "../../../store/actions/actionTypes";

const AdmissionList_Item_AdmTime = ({admission}) => {
    const dispatch = useDispatch();
    const {sendMessageIds} = useSelector(state => state.admission);
    const {selectedHospital} = useSelector(state => state.auth);

    const showModal = event => {
        event.preventDefault();
        event.stopPropagation();
        if (admission.pp_available) {
            dispatch({
                type: actionTypes.MODAL_ADMISSION_TIME_SHOW,
                admission,
                admTime: admission.timeMessage ? admission.timeMessage.json_data._admTime : admission.admissionTime,
                selectedMessageTemplate: selectedHospital.atm_templates[0]
            });
        } else {
            let errorMessage = 'TODO';
            if (admission.pp_availability_const === 'STATUS_PPA_NA_EMPTY_PHONE') {
                errorMessage = 'You cant use this feature, because admission have empty mobile phone';
            }
            if (admission.pp_availability_const === 'STATUS_PPA_NA_INVALID_PHONE') {
                errorMessage = `You cant use this feature, because admission have invalid mobile phone - ${admission.mobilePhone}`;
            }

            dispatch({
                type: actionTypes.MODAL_ERROR_SHOW,
                message: errorMessage,
            });
        }
    }
    const doNothing = event => {
        event.preventDefault();
        event.stopPropagation();
    }

    useEffect(() => {
        ReactTooltip.rebuild();
    })

    const renderButtonText = () => {
        if (sendMessageIds.includes(admission.id)) {
            return <img src={loadingGif} alt="Loading"/>;
        } else {
            if (admission.timeMessage) {
                return admission.timeMessage.json_data._admTime;
            } else {
                return '-';
            }
        }
    }

    if (admission.timeMessage) {
        switch (admission.timeMessage.status) {
            // TODO: refactor STATUS_DONE should be STATUS_VIEWED actually. STATUS_CONFIRMED is a "real" STATUS_DONE
            case 'STATUS_DONE':
                return (
                    <div className="d-flex flex-nowrap align-items-center" onClick={showModal} data-tip={`Patient viewed message at ${admission.timeMessage.opened_at}`}>
                        <button className={`btn btn-sm ${selectedHospital.patient_confirmMessages_viewedStyle ? selectedHospital.patient_confirmMessages_viewedStyle : 'btn-success' }`} onClick={showModal}>{renderButtonText()}</button>
                        <span className="info-tooltip"><i className="icon ico-c-info" /></span>
                    </div>
                );
            case 'STATUS_CONFIRMED':
                return (
                    <div className="d-flex flex-nowrap align-items-center" onClick={showModal} data-tip={`Patient confirmed message at ${admission.timeMessage.confirmed_at}`}>
                        <button className={`btn btn-sm ${selectedHospital.patient_confirmMessages_managerStyle ? selectedHospital.patient_confirmMessages_managerStyle : 'btn-success' }`} onClick={showModal}>{renderButtonText()}</button>
                        <span className="info-tooltip"><i className="icon ico-c-info" /></span>
                    </div>
                );
            default:
                return (
                    <div className="d-flex flex-nowrap align-items-center" onClick={showModal}>
                        <button className={`btn btn-sm ${selectedHospital.patient_confirmMessages_defaultStyle ? selectedHospital.patient_confirmMessages_defaultStyle : 'btn-primary' }`} onClick={showModal}>{renderButtonText()}</button>
                    </div>
                );
        }
    } else {
        return (
            <div className="d-flex flex-nowrap align-items-center" onClick={showModal}>
                <button className="btn btn-sm btn-secondary" onClick={showModal}>{renderButtonText()}</button>
            </div>
        );
    }
}

export default AdmissionList_Item_AdmTime;