import * as actionTypes from '../actions/actionTypes';
import produce from "immer";
import {MODAL_ADM_REG_DIFF_SEARCH, MODAL_SEND2_SHOW} from "../actions/actionTypes";

const initialState = {
    modalShowed: false,
    customUI_uploadPdf: {
        show: false,
        admission: {},
        customUI: {},
    },
    admissionDocumentFiles: {
        show: false,
        admission: {},
        document: {},
        files: [],
    },
    confirmAdmissionAction: {
        show: false,
        admission: {},
        actionLabel: '',
        actionDispatchable: null,
    },
    send2: {
        show: false,
        admission: {},
        actionLabel: '',
        actionDispatchable: null,
    },
    customUI_uploadPreviewPdf: {
        show: false,
        admission: {},
        customUI: {},
    },
    customUI_preAdmissionSet: {
        show: false,
        admission: {},
        customUI: {},
    },
    lifehouse_selectClinicalTeam: {
        show: false,
        admission: {},
    },
    lifehouse_pacRequired: {
        show: false,
        admission: {},
    },
    lifehouse_comments: {
        show: false,
        admission: {},
        commentsType: null,
    },
    comments: {
        show: false,
        admission: {},
        commentsType: null,
    },
    adm_reg_diff: {
        show: false,
        admission: {},
        data: {},
        search: '',
    },
    lifehouse_erfa: {
        show: false,
        admission: {},
    },
    clinicalFlags: {
        show: false,
        admission: {},
    },
    enablePatientPortal: {
        show: false,
        admission: {},
        enableLoading: false,
    },
    selectDocumentSet: {
        show: false,
        admission: {},
        enableLoading: false,
        documentSets: [],
        removePaymentOption: false,
    },
    documentStatus: {
        show: false,
        admission: {},
        enableLoading: false,
        documents: [],
        payments: [],
    },
    oecStatus: {
        show: false,
        admission: {},
        oecClaims: [],
    },
    oecStatus_proda: {
        show: false,
        admission: {},
        prodaRequests: [],
    },
    resendSms: {
        show: false,
        admission: {}
    },
    presentingIllnessCodes: {
        show: false,
        initialCodes: null,
        admission: {}
    },
    sendMessage: {
        show: false,
        admission: {}
    },
    admissionTime: {
        show: false,
        admTime: null,
        selectedMessageTemplate: {},
        admission: {}
    },
    admissionTimeCustomize: {
        show: false,
        admTime: null,
        selectedMessageTemplate: {},
        admission: {}
    },
    error: {
        show: false,
        message: ''
    },
    sendDocumentSetColumn: {
        show: false,
        admission: {},
        managerCustomUi: {},
    },
    admissionDocumentPdf: {
        show: false,
        admissionDocument: {},
        admission: {}
    },
    admissionUploadPdf: {
        show: false,
        file: false,
        admission: {},
        approving: false,
    },
    admissionViewPdf: {
        show: false,
        admission: {},
    },
    viewAdmissionMessage: {
        show: false,
        message: {},
    },
    archiveAdmissionDocuments: {
        show: false,
        admission: {},
    },
    archiveAdmissionDocuments2: {
        show: false,
        admission: {},
    },
    archiveLifehousePdf: {
        show: false,
        admission: {},
    },
    archivePatient: {
        show: false,
        admission: {},
    },
    admissionMessages: {
        show: false,
        admission: {},
        admissionMessages: [],
    },
    admissionDocuments: {
        show: false,
        admission: {},
        admissionDocuments: [],
    },
    admissionRfa: {
        show: false,
        admission: {},
        admissionRfa: [],
    },
    sendAdmissionDocuments: {
        show: false,
        admission: {},
        admissionDocumentTypes: [],
        parentModal: null,
    },
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MODAL_ADMISSION_DOCUMENT_FILES_SHOW:
            return {
                ...state,
                modalShowed: true,
                admissionDocumentFiles: {
                    show: true,
                    ...action
                }
            }
        case actionTypes.MODAL_ADMISSION_DOCUMENT_FILES_HIDE:
            return {
                ...state,
                modalShowed: false,
                admissionDocumentFiles: {
                    show: false,
                    ...action
                }
            }

        case actionTypes.MODAL_CONFIRM_ADMISSION_ACTION_SHOW:
            return {
                ...state,
                modalShowed: true,
                confirmAdmissionAction: {
                    show: true,
                    ...action
                }
            }
        case actionTypes.MODAL_CONFIRM_ADMISSION_ACTION_HIDE:
            return {
                ...state,
                modalShowed: false,
                confirmAdmissionAction: {
                    show: false,
                }
            }
        case actionTypes.MODAL_SEND2_SHOW:
            return {
                ...state,
                modalShowed: true,
                send2: {
                    show: true,
                    ...action
                }
            }
        case actionTypes.MODAL_SEND2_HIDE:
            return {
                ...state,
                modalShowed: false,
                send2: {
                    show: false,
                }
            }
        case actionTypes.MODAL_CUSTOM_UI_UPLOAD_PDF_SHOW:
            return {
                ...state,
                modalShowed: true,
                customUI_uploadPdf: {
                    show: true,
                    admission: action.admission,
                    customUI: action.customUI,
                }
            }
        case actionTypes.MODAL_CUSTOM_UI_UPLOAD_PDF_HIDE:
            return {
                ...state,
                modalShowed: false,
                customUI_uploadPdf: {
                    show: false,
                    admission: {},
                    customUI: {},
                }
            }
        case actionTypes.MODAL_CUSTOM_UI_PRE_ADMISSION_SET_SHOW:
            return {
                ...state,
                modalShowed: true,
                customUI_preAdmissionSet: {
                    show: true,
                    admission: action.admission,
                    customUI: action.customUI,
                }
            }
        case actionTypes.MODAL_CUSTOM_UI_PRE_ADMISSION_SET_HIDE:
            return {
                ...state,
                modalShowed: false,
                customUI_preAdmissionSet: {
                    show: false,
                    admission: {},
                    customUI: {},
                }
            }
        case actionTypes.MODAL_ARCHIVE_PATIENT_SHOW:
            return {
                ...state,
                modalShowed: true,
                archivePatient: {
                    show: true,
                    admission: action.admission,
                }
            }
        case actionTypes.MODAL_ARCHIVE_PATIENT_HIDE:
            return {
                ...state,
                modalShowed: false,
                archivePatient: {
                    show: false,
                    admission: {},
                }
            }
        case actionTypes.MODAL_CUSTOM_UI_UPLOAD_PREVIEW_PDF_SHOW:
            return {
                ...state,
                modalShowed: true,
                customUI_uploadPreviewPdf: {
                    show: true,
                    admission: action.admission,
                    customUI: action.customUI,
                }
            }
        case actionTypes.MODAL_CUSTOM_UI_UPLOAD_PREVIEW_PDF_HIDE:
            return {
                ...state,
                modalShowed: false,
                customUI_uploadPreviewPdf: {
                    show: false,
                    admission: {},
                    customUI: {},
                }
            }
        case actionTypes.MODAL_COMMENTS_SHOW:
            return {
                ...state,
                modalShowed: true,
                comments: {
                    show: true,
                    admission: action.admission,
                    commentsType: action.commentsType,
                    modalHeader: action.modalHeader,
                }
            }
        case actionTypes.MODAL_COMMENTS_HIDE:
            return {
                ...state,
                modalShowed: false,
                comments: {
                    show: false,
                    admission: null,
                    commentsType: null,
                    modalHeader: null,
                }
            }
        case actionTypes.MODAL_VIEW_ADMISSION_MESSAGE_SHOW:
            return {
                ...state,
                modalShowed: true,
                viewAdmissionMessage: {
                    show: true,
                    message: action.message,
                }
            }
        case actionTypes.MODAL_VIEW_ADMISSION_MESSAGE_HIDE:
            return {
                ...state,
                modalShowed: state.admissionMessages.show,
                viewAdmissionMessage: {
                    show: false,
                    message: {},
                }
            }
        case actionTypes.MODAL_LIFEHOUSE_SELECT_CLINICAL_TEAM_SHOW:
            return {
                ...state,
                modalShowed: true,
                lifehouse_selectClinicalTeam: {
                    show: true,
                    admission: action.admission,
                }
            }
        case actionTypes.MODAL_LIFEHOUSE_SELECT_CLINICAL_TEAM_HIDE:
            return {
                ...state,
                modalShowed: false,
                lifehouse_selectClinicalTeam: {
                    show: false,
                    admission: {},
                }
            }
        case actionTypes.MODAL_LIFEHOUSE_PAC_REQUIRED_SHOW:
            return {
                ...state,
                modalShowed: true,
                lifehouse_pacRequired: {
                    show: true,
                    admission: action.admission,
                }
            }
        case actionTypes.MODAL_LIFEHOUSE_PAC_REQUIRED_HIDE:
            return {
                ...state,
                modalShowed: false,
                lifehouse_pacRequired: {
                    show: false,
                    admission: {},
                }
            }
        case actionTypes.MODAL_LIFEHOUSE_COMMENTS_SHOW:
            return {
                ...state,
                modalShowed: true,
                lifehouse_comments: {
                    show: true,
                    admission: action.admission,
                    commentsType: action.commentsType ? action.commentsType : 1,
                }
            }
        case actionTypes.MODAL_LIFEHOUSE_COMMENTS_HIDE:
            return {
                ...state,
                modalShowed: false,
                lifehouse_comments: {
                    show: false,
                    admission: {},
                    commentsType: null,
                }
            }
        case actionTypes.MODAL_ADMISSION_VIEW_PDF_SHOW:
            return {
                ...state,
                modalShowed: true,
                admissionViewPdf: {
                    show: true,
                    admission: action.admission,
                }
            }
        case actionTypes.MODAL_ADMISSION_VIEW_PDF_HIDE:
            return {
                ...state,
                modalShowed: false,
                admissionViewPdf: {
                    show: false,
                    admission: {},
                }
            }
        case actionTypes.MODAL_ARCHIVE_ADMISSION_DOCUMENTS_SHOW:
            return {
                ...state,
                modalShowed: true,
                archiveAdmissionDocuments: {
                    show: true,
                    admission: action.admission,
                }
            }
        case actionTypes.MODAL_ARCHIVE_ADMISSION_DOCUMENTS_HIDE:
            return {
                ...state,
                modalShowed: false,
                archiveAdmissionDocuments: {
                    show: false,
                    admission: {},
                }
            }
        case actionTypes.MODAL_ARCHIVE_ADMISSION_DOCUMENTS2_SHOW:
            return {
                ...state,
                modalShowed: true,
                archiveAdmissionDocuments2: {
                    show: true,
                    admission: action.admission,
                    config: action.config,
                    admissionDocumentTypesWithoutSet: action.admissionDocumentTypesWithoutSet,
                }
            }
        case actionTypes.MODAL_ARCHIVE_ADMISSION_DOCUMENTS2_HIDE:
            return {
                ...state,
                modalShowed: false,
                archiveAdmissionDocuments2: {
                    show: false,
                    admission: {},
                    config: {},
                }
            }
        case actionTypes.MODAL_ADMISSION_UPLOAD_PDF_SET_FILE:
            return {
                ...state,
                admissionUploadPdf: {
                    ...state.admissionUploadPdf,
                    file: action.value,
                }
            }
        case actionTypes.MODAL_ADMISSION_UPLOAD_APPROVING:
            return {
                ...state,
                admissionUploadPdf: {
                    ...state.admissionUploadPdf,
                    approving: true,
                }
            }
        case actionTypes.MODAL_ADMISSION_UPLOAD_PDF_SHOW:
            return {
                ...state,
                modalShowed: true,
                admissionUploadPdf: {
                    show: true,
                    file: false,
                    approving: false,
                    admission: action.admission,
                }
            }
        case actionTypes.MODAL_ADMISSION_UPLOAD_PDF_HIDE:
            return {
                ...state,
                modalShowed: false,
                admissionUploadPdf: {
                    ...state.admissionUploadPdf,
                    show: false,
                    admission: {},
                }
            }
        case actionTypes.MODAL_PRESENTING_ILLNESS_CODES_SHOW:
            return {
                ...state,
                modalShowed: true,
                presentingIllnessCodes: {
                    show: true,
                    admission: action.admission,
                    initialCodes: action.admission.illCodes,
                }
            }
        case actionTypes.MODAL_PRESENTING_ILLNESS_CODES_HIDE:
            return {
                ...state,
                modalShowed: false,
                presentingIllnessCodes: {
                    show: false,
                    admission: {},
                }
            }

        case actionTypes.MODAL_ERROR_SHOW:
            return {
                ...state,
                modalShowed: true,
                error: {
                    show: true,
                    message: action.message,
                }
            }
        case actionTypes.MODAL_ERROR_HIDE:
            return {
                ...state,
                modalShowed: false,
                error: {
                    show: false,
                    message: '',
                }
            }

        case actionTypes.MODAL_ADMISSION_DOCUMENT_PDF_SHOW:
            return {
                ...state,
                modalShowed: true,
                admissionDocumentPdf: {
                    show: true,
                    admissionDocument: action.admissionDocument,
                    admission: action.admission,
                    customUIStatus: action.customUIStatus,
                    managerCustomUi: action.managerCustomUi,
                    jmphMedForm: action.jmphMedForm,
                }
            }
        case actionTypes.MODAL_ADMISSION_DOCUMENT_PDF_HIDE:
            return {
                ...state,
                modalShowed: false,
                admissionDocumentPdf: {
                    show: false,
                    admissionDocument: {},
                    admission: {},
                    jmphMedForm: false,
                }
            }

        case actionTypes.MODAL_SEND_DOCUMENT_SET_COLUMN_SHOW:
            return {
                ...state,
                modalShowed: true,
                sendDocumentSetColumn: {
                    show: true,
                    admission: action.admission,
                    managerCustomUi: action.managerCustomUi,
                }
            }
        case actionTypes.MODAL_SEND_DOCUMENT_SET_COLUMN_HIDE:
            return {
                ...state,
                modalShowed: false,
                sendDocumentSetColumn: {
                    show: false,
                    admission: {},
                    managerCustomUi: {},
                }
            }

        case actionTypes.MODAL_ADMISSION_TIME_CUSTOMIZE_SELECT_TEMPLATE:
            return {
                ...state,
                admissionTimeCustomize: {
                    ...state.admissionTimeCustomize,
                    selectedMessageTemplate: action.selectedMessageTemplate,
                }
            }

        case actionTypes.MODAL_ADMISSION_TIME_CUSTOMIZE_HIDE:
            return {
                ...state,
                modalShowed: false,
                admissionTimeCustomize: {
                    show: false,
                    admTime: null,
                    selectedMessageTemplate: {},
                    admission: {},
                }
            }
        case actionTypes.MODAL_ADMISSION_TIME_CUSTOMIZE:
            return {
                ...state,
                modalShowed: true,
                admissionTimeCustomize: {
                    show: true,
                    admTime: state.admissionTime.admTime,
                    selectedMessageTemplate: state.admissionTime.selectedMessageTemplate,
                    admission: state.admissionTime.admission,
                    extraFields: state.admissionTime.extraFields
                },
                admissionTime: {
                    show: false,
                    admTime: null,
                    selectedMessageTemplate: {},
                    admission: {},
                    extraFields: {},
                }
            }
        case actionTypes.MODAL_ADMISSION_TIME_RESTART:
            return {
                ...state,
                modalShowed: true,
                admissionTime: {
                    show: true,
                    extraFields: state.admissionTimeCustomize.extraFields,
                    admTime: state.admissionTimeCustomize.admTime,
                    selectedMessageTemplate: state.admissionTimeCustomize.selectedMessageTemplate,
                    admission: state.admissionTimeCustomize.admission,
                },
                admissionTimeCustomize: {
                    show: false,
                    admTime: null,
                    selectedMessageTemplate: {},
                    admission: {},
                    extraFields: {},
                }
            }

        case actionTypes.MODAL_RESEND_SMS_SHOW:
            return {
                ...state,
                modalShowed: true,
                resendSms: {
                    show: true,
                    admission: action.admission,
                }
            }
        case actionTypes.MODAL_RESEND_SMS_HIDE:
            return {
                ...state,
                modalShowed: false,
                resendSms: {
                    show: false,
                    admission: {},
                }
            }

        case actionTypes.MODAL_SEND_MESSAGE_SHOW:
            return {
                ...state,
                modalShowed: true,
                sendMessage: {
                    show: true,
                    admission: action.admission,
                }
            }
        case actionTypes.MODAL_SEND_MESSAGE_HIDE:
            return {
                ...state,
                modalShowed: state.admissionMessages.show,
                sendMessage: {
                    show: false,
                    admission: {},
                }
            }

        case actionTypes.MODAL_ADMISSION_TIME_SHOW:
            return {
                ...state,
                modalShowed: true,
                admissionTime: {
                    show: true,
                    admTime: action.admTime,
                    selectedMessageTemplate: action.selectedMessageTemplate,
                    admission: action.admission,
                    extraFields: {}
                }
            }
        case actionTypes.MODAL_ADMISSION_TIME_SET_ADM_TIME:
            return {
                ...state,
                admissionTime: {
                    ...state.admissionTime,
                    admTime: action.admTime,
                }
            }
        case actionTypes.MODAL_ADMISSION_TIME_SELECT_TEMPLATE:
            return {
                ...state,
                admissionTime: {
                    ...state.admissionTime,
                    extraFields: {},
                    selectedMessageTemplate: action.selectedMessageTemplate,
                }
            }
        case actionTypes.MODAL_ADMISSION_TIME_HIDE:
            return {
                ...state,
                modalShowed: false,
                admissionTime: {
                    show: false,
                    admission: {},
                }
            }
        case actionTypes.MODAL_ADMISSION_TIME_SET_EXTRA_FIELD:
           return produce(state, draft => {
               draft.admissionTime.extraFields[action.variable] = action.value;
           });

        case actionTypes.MODAL_SELECT_DOCUMENT_SET_TOGGLE:
            return {
                ...state,
                selectDocumentSet: {
                    ...state.selectDocumentSet,
                    removePaymentOption: action.removePaymentOption,
                }
            }
        case actionTypes.MODAL_SELECT_DOCUMENT_SET_SHOW:
            return {
                ...state,
                modalShowed: true,
                selectDocumentSet: {
                    show: true,
                    admission: action.admission,
                    enableLoading: false,
                    documentSets: action.documentSets,
                    removePaymentOption: false,
                }
            }
        case actionTypes.MODAL_SELECT_DOCUMENT_SET_HIDE:
            return {
                ...state,
                modalShowed: false,
                selectDocumentSet: {
                    show: false,
                    admission: {},
                    enableLoading: false,
                    documentSets: [],
                }
            }

        case actionTypes.MODAL_OEC_STATUS_SHOW:
            return {
                ...state,
                modalShowed: true,
                oecStatus: {
                    show: true,
                    admission: action.admission,
                    oecClaims: action.oecClaims,
                }
            }
        case actionTypes.MODAL_OEC_STATUS_HIDE:
            return {
                ...state,
                modalShowed: false,
                oecStatus: {
                    show: false,
                    admission: {},
                    oecClaims: [],
                }

            }

        case actionTypes.MODAL_OEC_STATUS_PRODA_SHOW:
            return {
                ...state,
                modalShowed: true,
                oecStatus_proda: {
                    show: true,
                    admission: action.admission,
                    prodaRequests: action.prodaRequests,
                }
            }
        case actionTypes.MODAL_OEC_STATUS_PRODA_HIDE:
            return {
                ...state,
                modalShowed: false,
                oecStatus_proda: {
                    show: false,
                    admission: {},
                    prodaRequests: [],
                }

            }

        case actionTypes.MODAL_DOCUMENT_STATUS_SHOW:
            return {
                ...state,
                modalShowed: true,
                documentStatus: {
                    show: true,
                    admission: action.admission,
                    enableLoading: false,
                    documents: action.documents,
                    payments: action.payments,
                }
            }
        case actionTypes.MODAL_DOCUMENT_STATUS_HIDE:
            return {
                ...state,
                modalShowed: false,
                documentStatus: {
                    show: false,
                    admission: {},
                    enableLoading: false,
                    documents: [],
                    payments: [],
                }
            }

        case actionTypes.MODAL_ENABLE_PATIENT_PORTAL_SHOW:
            return {
                ...state,
                modalShowed: true,
                enablePatientPortal: {
                    show: true,
                    admission: action.admission,
                    enableLoading: false,
                }
            }
        case actionTypes.MODAL_ENABLE_PATIENT_PORTAL_HIDE:
            return {
                ...state,
                modalShowed: false,
                enablePatientPortal: {
                    show: false,
                    admission: {},
                    enableLoading: false,
                }
            }
        case actionTypes.MODAL_ENABLE_PATIENT_PORTAL_LOADING:
            return {
                ...state,
                enablePatientPortal: {
                    ...state.enablePatientPortal,
                    enableLoading: true,
                }
            }

        case actionTypes.MODAL_CLINICAL_FLAGS_SHOW:
            return {
                ...state,
                modalShowed: true,
                clinicalFlags: {
                    show: true,
                    admission: action.admission,
                }
            }
        case actionTypes.MODAL_CLINICAL_FLAGS_HIDE:
            return {
                ...state,
                modalShowed: false,
                clinicalFlags: {
                    show: false,
                    admission: {},
                }
            }
        case actionTypes.MODAL_LIFEHOUSE_ERFA_SHOW:
            return {
                ...state,
                modalShowed: true,
                lifehouse_erfa: {
                    show: true,
                    admission: action.admission,
                }
            }
        case actionTypes.MODAL_LIFEHOUSE_ERFA_HIDE:
            return {
                ...state,
                modalShowed: false,
                lifehouse_erfa: {
                    show: false,
                    admission: {},
                }
            }
        case actionTypes.MODAL_ADM_REG_DIFF_SHOW:
            return {
                ...state,
                modalShowed: true,
                adm_reg_diff: {
                    show: true,
                    admission: action.admission,
                    data: action.data,
                    emrView: action.emrView,
                    search: '',
                }
            }
        case actionTypes.MODAL_ADM_REG_DIFF_SEARCH:
            return {
                ...state,
                adm_reg_diff: {
                    ...state.adm_reg_diff,
                    search: action.search,
                }
            }
        case actionTypes.MODAL_ADM_REG_DIFF_HIDE:
            return {
                ...state,
                modalShowed: false,
                adm_reg_diff: {
                    show: false,
                    admission: {},
                    data: {},
                    emrView: false,
                    search: '',
                }
            }

        case actionTypes.MODAL_ADMISSION_MESSAGES_SHOW:
            return {
                ...state,
                modalShowed: true,
                admissionMessages: {
                    show: true,
                    admission: action.admission,
                    admissionMessages: action.admissionMessages,
                }
            }
        case actionTypes.MODAL_ADMISSION_MESSAGES_HIDE:
            return {
                ...state,
                modalShowed: false,
                admissionMessages: {
                    show: false,
                    admission: {},
                    admissionMessages: [],
                }
            }

        case actionTypes.MODAL_ADMISSION_DOCUMENTS_SHOW:
            return {
                ...state,
                modalShowed: true,
                admissionDocuments: {
                    show: true,
                    admission: action.admission,
                    admissionDocuments: action.admissionDocuments,
                }
            }
        case actionTypes.MODAL_ADMISSION_DOCUMENTS_HIDE:
            return {
                ...state,
                modalShowed: false,
                admissionDocuments: {
                    show: false,
                    admission: {},
                    admissionDocuments: [],
                }
            }

        case actionTypes.MODAL_ADMISSION_RFA_SHOW:
            return {
                ...state,
                modalShowed: true,
                admissionRfa: {
                    show: true,
                    admission: action.admission,
                    admissionRfa: action.admissionRfa,
                }
            }
        case actionTypes.MODAL_ADMISSION_RFA_HIDE:
            return {
                ...state,
                modalShowed: false,
                admissionRfa: {
                    show: false,
                    admission: {},
                    admissionRfa: [],
                }
            }

        case actionTypes.MODAL_SEND_ADMISSION_DOCUMENT_SHOW:
            return {
                ...state,
                modalShowed: true,
                sendAdmissionDocuments: {
                    show: true,
                    admission: action.admission,
                    admissionDocumentTypes: action.admissionDocumentTypes,
                    parentModal: action.parentModal,
                }
            }
        case actionTypes.MODAL_SEND_ADMISSION_DOCUMENT_HIDE:
            return {
                ...state,
                modalShowed: state.sendAdmissionDocuments.parentModal ? state[state.sendAdmissionDocuments.parentModal].show : false,
                sendAdmissionDocuments: {
                    show: false,
                    admission: {},
                    admissionDocumentTypes: [],
                }
            }

        case actionTypes.MODAL_ARCHIVE_CUSTOM_PDF_SHOW:
            return {
                ...state,
                modalShowed: true,
                archiveLifehousePdf: {
                    show: true,
                    admission: action.admission,
                }
            }
        case actionTypes.MODAL_ARCHIVE_CUSTOM_PDF_HIDE:
            return {
                ...state,
                modalShowed: false,
                archiveLifehousePdf: {
                    show: false,
                    admission: {},
                }
            }

        default:
            return state;
    }
}

export default reducer;