// https://www.smashingmagazine.com/2020/03/sortable-tables-react/

import React from "react";
import {useSelector} from "react-redux";
import * as _ from "lodash";

import {subfilterVariables} from './subfilters';

const useFilteredData = (items) => {
    const subfilters = useSelector(state => state.admissionList.subfilters);
    const dynamicFilters = useSelector(state => state.auth.selectedHospital.filters);

    const isIncluded = (item, filter, value) => {
        switch (filter)
        {
            case "WARD":
                return !value || !value.length || (item[`adm2_ward`] && value.includes(item[`adm2_ward`]));
            case "CLAIM_CODE":
                return !value || !value.length || (item[`adm2_claimCode`] && value.includes(item[`adm2_claimCode`]));
            case "FUNDING_GROUP":
                if (!value || !value.length) {
                    return true;
                }
                let codes = [];
                value.forEach(item => {codes = codes.concat(dynamicFilters.fundingGroups[item])});
                return (item[`adm2_claimCode`] && codes.includes(item[`adm2_claimCode`]));
            case "MATERNITY":
                if (!value || !value.length) {
                    return true;
                }
                let maternity_codes = [];
                value.forEach(item => {maternity_codes = maternity_codes.concat(dynamicFilters.maternity[item])});
                return (item[`adm2_ward`] && maternity_codes.includes(item[`adm2_ward`]));
            case "reg":
                if (value === true) {
                    return item[`lifehouseFlags_${filter}`]['status'] === "STATUS_COMPLETED";
                } else {
                    return item[`lifehouseFlags_${filter}`]['status'] !== "STATUS_COMPLETED";
                }
            case "book":
            case "preg":
            case "adm":
                return item[`lifehouseFlags_${filter}`] === value;
            case "hist":
                if (value === true) {
                    return item.clinicalFlags_summaryRating !== undefined;
                } else {
                    return item.clinicalFlags_summaryRating === undefined;
                }
            case "pac":
                if (value === true) {
                    return item.lifehouseFlags_pac !== false;
                } else {
                    return item.lifehouseFlags_pac === false;
                }
            default: // paid/unpaid
                return item['subFilters'][filter] === value;
        }
    }

    const filteredItems = React.useMemo(() => {
        let sortableItems = [];

        items.forEach(item => {
            let includeItem = true;
            _.forIn(subfilters, (value, key) => {
                // console.log('[filteredItems] subfilters', key, value);
                if ((value !== undefined) && !isIncluded(item, key, value)) {
                    console.log('[filteredItems] FALSE', false);
                    includeItem = false;
                }
            });
            if (includeItem) {
                sortableItems.push(item);
            }
        })
        console.log('[useFilteredData]', subfilters, sortableItems);
        return sortableItems;
    }, [items, subfilters]);

    return { filteredItems };
}

export default useFilteredData;