import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import useOnClickOutside from "use-onclickoutside";

import * as actionTypes from "../../../store/actions/actionTypes";
import UrDt from "../../CustomLogic/UrDt";
import AdmDt from "../../CustomLogic/AdmDt";
import DevUiData from "../../Dev/DevUiData";
import {send2} from "../../../store/actions/customUI";

const Send2 = () => {
    const {show, admission, actionLabel, actionDispatchable, customUI} = useSelector(state => state.modal.send2);
    const {devSmsTarget_enable, devSmsTarget_phone} = useSelector(state => state.auth);
    const [sms, setSms] = useState('');
    const [smsEnabled, setSmsEnabled] = useState(false)

    const dispatch = useDispatch();
    const ref = React.useRef(null);
    useOnClickOutside(ref, () => hideModal());

    useEffect(() => {
        setSmsEnabled(customUI.sms_enable);
        setSms(customUI.sms);
    }, []);
    const hideModal = () => dispatch({type: actionTypes.MODAL_SEND2_HIDE});

    const toggleSmsEnabled = event => {
        setSmsEnabled(event.target.checked);
    }
    const handleSmsChange = event => setSms(event.target.value);

    const doAction = () => {
        dispatch({type: actionTypes.RELOADING_PROGRESS_START, id: admission.id});
        dispatch(send2(admission, customUI, smsEnabled, sms));
        hideModal();
    }

    const showTargetPhone = () => {
        if (devSmsTarget_enable) {
            return (
                <dd><strike>{admission.mobilePhone}</strike> <span className="badge badge-danger">DEV TARGET ENABLED = {devSmsTarget_phone}</span></dd>
            );
        } else {
            return (
                <dd>{admission.mobilePhone}</dd>
            );
        }
    }

    const renderModal = () => {
        return (
            <div className="modal-dialog" role="document" ref={ref}>
            <div className="modal-content modal-content-fit">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideModal}>
                    <span aria-hidden="true">×</span>
                </button>
                <div className="modal-body">
                    <h3>{actionLabel} for {admission.title} {admission.surname}, {admission.name}</h3>
                    <dl className="person-list mb-3">
                        <UrDt admission={admission}/>
                        <AdmDt admission={admission}/>
                        <dt>Mobile</dt>
                        {showTargetPhone()}
                    </dl>
                    <form action="#" className="person-info-form">
                        <div className="form-group mb-4 lifehouse-comments">
                            <DevUiData data={{customUI}}/>
                            <div className="col-12">
                                <div className="custom-control custom-switch custom-switch-right-side">
                                    <input type="checkbox" className="custom-control-input"
                                           id="smsNotificationSwitch" checked={smsEnabled} onChange={toggleSmsEnabled}/>
                                    <label className="custom-control-label"
                                           htmlFor="smsNotificationSwitch">Send Sms notification?</label>

                            </div>
                        </div>
                        <div className="col-12">
                                <div className="custom-control custom-switch custom-switch-right-side">
                            <strong>Patient sms</strong>
                            <textarea
                                onChange={handleSmsChange}
                                value={sms}
                                disabled={!smsEnabled}
                            ></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                        <div className="row">
                                <div className="col-12 d-flex justify-content-end">
                                    <input type="button" value={actionLabel} className="btn btn-primary" onClick={doAction}/>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        );
    }

    return (
        <>
            <div className={`modal fade ${show ? 'show' : ''}`} id="personModal" tabIndex="-1" role="dialog"
                 aria-labelledby="personModalLabel" aria-modal="true">
                {show && renderModal()}
            </div>
        </>
    );
}

export default Send2;