import * as actionTypes from './actionTypes';
import axios from 'axios';
import {push} from "react-router-redux";
import {errorEvent} from "../../utils/axiosErrors";
import {MODAL_ARCHIVE_PATIENT_HIDE} from "./actionTypes";
import {admissionListReload} from "./admissionList";

export const load = (id) => {
    return (dispatch, getState) => {
        console.log('[actions/Admission] load', id);
        dispatch({type: actionTypes.LOADING_START});
        const idToken = getState().auth.idToken;

        console.log('[action/admissions] fetchData', id);

        axios.get(
            `/admission/${id}`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[action/admissions] response', response.data);
                dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                dispatch({type: actionTypes.LOADING_DONE});
                dispatch({type: actionTypes.AUTH_SELECT_HOSPITAL, ...response.data.admission.hospital});
            })
            .catch((error) => {
                dispatch(errorEvent(error, `/admission/${id}`));
            })
        ;

    }
}

export const sendMessage = (admission, addToast, title, text, smsNotification, smsText, vars = {}) => {
    return (dispatch, getState) => {
        console.log('[action/admissions] sendMessage', admission.id);
        const idToken = getState().auth.idToken;
        dispatch({type: actionTypes.SEND_MESSAGE_START, id: admission.id});

        axios.post(
            `/admission/${admission.id}/send-message`,
            {title, text, smsNotification, smsText, vars},
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[action/admissions] sendMessage response', response.data);
                // dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                // dispatch({type: actionTypes.LOADING_DONE});
                if (response.data.done) {
                    addToast(`Message sent for ${admission.label}`, {
                        appearance: 'success',
                        autoDismiss: false
                    });
                    dispatch({type: actionTypes.SEND_MESSAGE_DONE, id: admission.id});
                    const currentAdmission = getState().admission.admission;
                    if (currentAdmission && (currentAdmission.id === admission.id)) {
                        console.log('[action/admissions] sendMessage fast reload');
                        dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                    }
                    dispatch({type: actionTypes.ADMISSION_UPDATE, admission: response.data.admission});
                } else {
                    addToast(`Failed to send message for admission ${admission.label} - got exception ${response.data.customStatus}`, {
                        appearance: 'error',
                        autoDismiss: false
                    });
                    dispatch({type: actionTypes.SEND_MESSAGE_DONE, id: admission.id});
                }
            });
    }
}
export const addAdmission = (data, errorCallback) => {
    return (dispatch, getState) => {
        console.log('[action/admission] addAdmission', data);
        const idToken = getState().auth.idToken;
        dispatch({type: actionTypes.LOADING_START});

        axios.post(
            `/admission/add`,
            data,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[action/admission] addAdmission response', response.data);
                // dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                // dispatch({type: actionTypes.LOADING_DONE});
                if (response.data.done) {
                    dispatch(push(`/admission/${response.data.id}`))
                } else {
                    errorCallback(response.data.customStatus);
                    dispatch({type: actionTypes.LOADING_DONE});
                }
            });
    }
}

export const resendSms = (admission, addToast) => {
    return (dispatch, getState) => {
        console.log('[action/admissions] resendSms', admission.id);
        const idToken = getState().auth.idToken;
        dispatch({type: actionTypes.RESEND_SMS_START, id: admission.id});
        dispatch({type: actionTypes.MODAL_RESEND_SMS_HIDE});

        axios.get(
            `/admission/${admission.id}/resend-sms`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[action/admissions] resendSms response', response.data);
                // dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                // dispatch({type: actionTypes.LOADING_DONE});
                if (response.data.done) {
                    addToast(`Sms resent for admission ${admission.label}`, {
                        appearance: 'success',
                        autoDismiss: false
                    });
                    dispatch({type: actionTypes.RESEND_SMS_DONE, id: admission.id});
                    const currentAdmission = getState().admission.admission;
                    if (currentAdmission && (currentAdmission.id === admission.id)) {
                        console.log('[action/admissions] resendSms fast reload');
                        dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                    }
                    dispatch({type: actionTypes.ADMISSION_UPDATE, admission: response.data.admission});
                } else {
                    addToast(`Failed to resend sms for admission ${admission.label}`, {
                        appearance: 'error',
                        autoDismiss: false
                    });
                    dispatch({type: actionTypes.RESEND_SMS_DONE, id: admission.id});
                }
            });
    }
}

export const archiveAdmissionDocuments = (admission, addToast) => {
    return (dispatch, getState) => {
        console.log('[action/admissions] archiveAdmissionDocuments', admission.id);
        const idToken = getState().auth.idToken;
        dispatch({type: actionTypes.ARCHIVE_ADMISSION_DOCUMENTS_START, id: admission.id});
        dispatch({type: actionTypes.MODAL_ARCHIVE_ADMISSION_DOCUMENTS_HIDE});

        axios.get(
            `/admission/${admission.id}/archive-admission-documents`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[action/admissions] archiveAdmissionDocuments response', response.data);
                // dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                // dispatch({type: actionTypes.LOADING_DONE});
                if (response.data.done) {
                    addToast(`Admission Documents Archived for admission ${admission.label}`, {
                        appearance: 'success',
                        autoDismiss: false
                    });
                    dispatch({type: actionTypes.ARCHIVE_ADMISSION_DOCUMENTS_DONE, id: admission.id});
                    const currentAdmission = getState().admission.admission;
                    if (currentAdmission && (currentAdmission.id === admission.id)) {
                        console.log('[action/admissions] resendSms fast reload');
                        dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                    }
                    dispatch({type: actionTypes.ADMISSION_UPDATE, admission: response.data.admission});
                } else {
                    addToast(`Failed to archive Admission Documents for admission ${admission.label} - ${response.data.error}`, {
                        appearance: 'error',
                        autoDismiss: false
                    });
                    dispatch({type: actionTypes.ARCHIVE_ADMISSION_DOCUMENTS_DONE, id: admission.id});
                }
            });
    }
}
export const archiveAdmissionDocuments2_init = (admission, addToast) => {
    return (dispatch, getState) => {
        console.log('[action/admissions] archiveAdmissionDocuments2_init', admission.id);
        const idToken = getState().auth.idToken;
        dispatch({type: actionTypes.LOADING_START});

        axios.get(
            `/admission/${admission.id}/archive-admission-documents2/init`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[action/admissions] archiveAdmissionDocuments2_init response', response.data);
                dispatch({type: actionTypes.LOADING_DONE});

                if (response.data.done) {
                    dispatch({type: actionTypes.MODAL_ARCHIVE_ADMISSION_DOCUMENTS2_SHOW, admission, config: response.data.config, admissionDocumentTypesWithoutSet: response.data.admissionDocumentTypesWithoutSet});
                } else {
                    addToast(`Cant archive Admission Documents for admission ${admission.label} - ${response.data.error}`, {
                        appearance: 'error',
                        autoDismiss: false
                    });
                    dispatch({type: actionTypes.ARCHIVE_ADMISSION_DOCUMENTS_DONE, id: admission.id});
                }
            });
    }
}

export const archiveAdmissionDocuments2 = (admission, addToast, skip, documentTypes) => {
    return (dispatch, getState) => {
        console.log('[action/admissions] archiveAdmissionDocuments2', admission.id, documentTypes);
        const idToken = getState().auth.idToken;
        dispatch({type: actionTypes.ARCHIVE_ADMISSION_DOCUMENTS_START, id: admission.id});
        dispatch({type: actionTypes.MODAL_ARCHIVE_ADMISSION_DOCUMENTS2_HIDE});

        axios.post(
            `/admission/${admission.id}/archive-admission-documents2`,
            {skip, documentTypes},
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[action/admissions] archiveAdmissionDocuments2 response', response.data);
                // dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                // dispatch({type: actionTypes.LOADING_DONE});
                if (response.data.done) {
                    addToast(`Admission Documents Archived for admission ${admission.label}`, {
                        appearance: 'success',
                        autoDismiss: false
                    });
                    dispatch({type: actionTypes.ARCHIVE_ADMISSION_DOCUMENTS_DONE, id: admission.id});
                    const currentAdmission = getState().admission.admission;
                    if (currentAdmission && (currentAdmission.id === admission.id)) {
                        console.log('[action/admissions] resendSms fast reload');
                        dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                    }
                    dispatch({type: actionTypes.ADMISSION_UPDATE, admission: response.data.admission});
                } else {
                    addToast(`Failed to archive Admission Documents for admission ${admission.label} - ${response.data.error}`, {
                        appearance: 'error',
                        autoDismiss: false
                    });
                    dispatch({type: actionTypes.ARCHIVE_ADMISSION_DOCUMENTS_DONE, id: admission.id});
                }
            });
    }
}


export const archivePatient = (admission, addToast) => {
    return (dispatch, getState) => {
        console.log('[action/admissions] archivePatient', admission.id);
        const idToken = getState().auth.idToken;
        dispatch({type: actionTypes.RELOADING_PROGRESS_START, id: admission.id});
        dispatch({type: actionTypes.MODAL_ARCHIVE_PATIENT_HIDE});

        axios.get(
            `/admission/${admission.id}/archive-patient`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[action/admissions] archivePatient response', response.data);
                dispatch({type: actionTypes.RELOADING_PROGRESS_DONE, id: admission.id});

                if (response.data.done) {
                    addToast(`Archived Patient ${admission.label}`, {
                        appearance: 'success',
                        autoDismiss: false
                    });
                    window.location.reload();
                } else {
                    addToast(`Failed to archive Patient - ${admission.label} - ${response.data.error}`, {
                        appearance: 'error',
                        autoDismiss: false
                    });
                }
            });
    }
}

export const archiveLifehousePdf = (admission, addToast) => {
    return (dispatch, getState) => {
        console.log('[action/admissions] archiveLifehousePdf', admission.id);
        const idToken = getState().auth.idToken;
        dispatch({type: actionTypes.RELOADING_PROGRESS_START, id: admission.id});
        dispatch({type: actionTypes.MODAL_ARCHIVE_CUSTOM_PDF_HIDE});

        axios.get(
            `/admission/${admission.id}/archive-lifehouse-pdf`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[action/admissions] archiveLifehousePdf response', response.data);

                if (response.data.done) {
                    addToast(`Uploaded paper docs Archived for admission ${admission.label}`, {
                        appearance: 'success',
                        autoDismiss: false
                    });
                    dispatch({type: actionTypes.RELOADING_PROGRESS_DONE, id: admission.id});
                    const currentAdmission = getState().admission.admission;
                    if (currentAdmission && (currentAdmission.id === admission.id)) {
                        console.log('[action/admissions] archiveLifehousePdf fast reload');
                        dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                    }
                    dispatch({type: actionTypes.ADMISSION_UPDATE, admission: response.data.admission});
                } else {
                    addToast(`Failed to archive paper docs for admission ${admission.label} - ${response.data.error}`, {
                        appearance: 'error',
                        autoDismiss: false
                    });
                    dispatch({type: actionTypes.RELOADING_PROGRESS_DONE, id: admission.id});
                }
            });
    }
}


export const sendManagerCustomUi = (admission, addToast, managerCustomUi) => {
// ======================================================
    return (dispatch, getState) => {
        console.log('[action/admissions] sendManagerCustomUi', admission.id);
        const idToken = getState().auth.idToken;
        dispatch({type: actionTypes.RELOADING_CUSTOM_COLUMN_START, id: admission.id});

        axios.post(
            `/admission/${admission.id}/send-manager-custom-ui/${managerCustomUi.id}`,
            {},
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                dispatch({type: actionTypes.RELOADING_CUSTOM_COLUMN_DONE, id: admission.id});
                dispatch({type: actionTypes.ADMISSION_UPDATE, admission: response.data.admission});
            });
    }
}

export const sendAdmissionDocument = (admission, addToast, admissionDocumentType, smsNotification) => {
    return (dispatch, getState) => {
        console.log('[action/admissions] sendAdmissionDocument', admission.id, admissionDocumentType);
        const idToken = getState().auth.idToken;
        // dispatch({type: actionTypes.SEND_MESSAGE_START, id: admission.id});

        axios.post(
            `/admission/${admission.id}/send-admission-document`,
            {admissionDocumentType, smsNotification},
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[action/admissions] sendAdmissionDocument response', response.data);
                // dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                // dispatch({type: actionTypes.LOADING_DONE});
                if (response.data.done) {
                    addToast(`Form sent for admission ${admission.label}`, {
                        appearance: 'success',
                        autoDismiss: false
                    });
                    // dispatch({type: actionTypes.SEND_MESSAGE_DONE, id: admission.id});
                    const currentAdmission = getState().admission.admission;
                    if (currentAdmission && (currentAdmission.id === admission.id)) {
                        console.log('[action/admissions] sendAdmissionDocument fast reload');
                        dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                    }
                    dispatch({type: actionTypes.ADMISSION_UPDATE, admission: response.data.admission});
                } else {
                    addToast(`Failed to send Form for admission ${admission.label} - got exception ${response.data.customStatus}`, {
                        appearance: 'error',
                        autoDismiss: false
                    });
                    // dispatch({type: actionTypes.SEND_MESSAGE_DONE, id: admission.id});
                }
            });
    }
}

export const addComment = (admission, body, commentsType, status, addToast) => {
    return (dispatch, getState) => {
        dispatch({type: actionTypes.RELOADING_PROGRESS_START, id: admission.id});

        const idToken = getState().auth.idToken;

        console.log('[actions/admission] addComment', admission.id);
        dispatch({type: actionTypes.MODAL_COMMENTS_HIDE});

        axios.post(
            `${process.env.REACT_APP_API_PATH}/admission/${admission.id}/add-comment`,
            {body, status, commentsType},
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[actions/admission] addComment reload admissions list');
                dispatch({type: actionTypes.RELOADING_PROGRESS_DONE, id: admission.id});
                dispatch({type: actionTypes.ADMISSION_UPDATE, admission: response.data.admission});
                addToast(`Comment added for ${admission.label}`, {
                    appearance: 'success',
                    autoDismiss: true
                });
            });
    }
}