import React from 'react';
import {useSelector} from "react-redux";
import AdmissionList_Item_ManagerCustomUI_UploadPdf from "./AdmissionList_Item_ManagerCustomUI_UploadPdf";
import AdmissionList_Item_ManagerCustomUI_PreAdmission from "./AdmissionList_Item_ManagerCustomUI_PreAdmission";
import AdmissionList_Item_ManagerCustomUI_SendRegMed from "./AdmissionList_Item_ManagerCustomUI_SendRegMed";
import AdmissionList_Item_ManagerCustomUI_Comments from "./AdmissionList_Item_ManagerCustomUI_Comments";
import AdmissionList_Item_ManagerCustomUI_Send2 from "./AdmissionList_Item_ManagerCustomUI_Send2";
import {get} from "dot-prop-immutable";

const AdmissionList_Item_ManagerCustomUI = ({admission, position}) => {
    const managerCustomUI_unified = useSelector(state => state.auth.selectedHospital.managerCustomUI_unified);
    const _items = get(managerCustomUI_unified, `admission_list.${position}`, []);

    if (!_items.length) return null;

    const render = (customUI, key) => {
        switch (customUI.type) {
            case 'TYPE_ADMISSION_LIST_UPLOAD_PDF':
                return <td><AdmissionList_Item_ManagerCustomUI_UploadPdf key={key} admission={admission}
                                                                         customUI={customUI}/></td>
            case 'TYPE_ADMISSION_LIST_PRE_ADMISSION_INTEGRATION':
                return <td><AdmissionList_Item_ManagerCustomUI_PreAdmission key={key} admission={admission}
                                                                            customUI={customUI}/></td>
            case 'TYPE_ADMISSION_LIST_COMMENTS':
                return <AdmissionList_Item_ManagerCustomUI_Comments key={key} admission={admission} customUI={customUI} />
            case 'TYPE_ADMISSION_LIST_SEND_REG_MED':
                return <AdmissionList_Item_ManagerCustomUI_SendRegMed key={key} admission={admission} customUI={customUI} />
            case 'TYPE_ADMISSION_LIST_COLUMN_SEND2':
                return <AdmissionList_Item_ManagerCustomUI_Send2 key={key} admission={admission} customUI={customUI} />
            default:
                console.error("Unknown CustomUI custom UI type", customUI);
                break;
        }
    }

    return _items.map(render);
}

export default AdmissionList_Item_ManagerCustomUI;