import React from 'react';
import {useSelector} from "react-redux";
import {get} from "dot-prop-immutable";

const AdmissionList_Header_ManagerCustomUI = ({position, getClassNamesFor, requestSort}) => {
    const managerCustomUI_unified = useSelector(state => state.auth.selectedHospital.managerCustomUI_unified);
    const _items = get(managerCustomUI_unified, `admission_list.${position}`, []);

    if (!_items.length) return null;

    const renderHeader = (customUI, key) => {
        switch (customUI.type) {
            case 'TYPE_ADMISSION_LIST_UPLOAD_PDF':
                return <th className="no-sort"><strong className="title" key={key}>{customUI.columnTitle}</strong></th>
            case 'TYPE_ADMISSION_LIST_PRE_ADMISSION_INTEGRATION':
                return <th className="no-sort"><strong className="title" key={key}>{customUI.columnTitle}</strong></th>
            case 'TYPE_ADMISSION_LIST_COMMENTS':
                return <th className={`${getClassNamesFor(customUI.sort_property)} custom-ui-sort-header`}
                           onClick={() => requestSort(customUI.sort_property, false)}><strong className="title"
                                                                                              key={`comm_${key}`}>{customUI.header_title}</strong>
                </th>
            case 'TYPE_ADMISSION_LIST_SEND_REG_MED':
                return <>
                    <th className={getClassNamesFor('customui_reg_sort')} onClick={() => requestSort('customui_reg_sort')}><strong className="title" key={`reg_${key}`}>REG</strong></th>
                    <th className={getClassNamesFor('customui_med_sort')} onClick={() => requestSort('customui_med_sort')}><strong className="title" key={`med_${key}`}>MED</strong></th>
                </>
            case 'TYPE_ADMISSION_LIST_COLUMN_SEND2':
                if (customUI.sort_property) {
                    return (
                        <th className={getClassNamesFor(customUI.position)} onClick={() => requestSort(customUI.position)}><strong className="title" key={`customui_${key}`}>{customUI.columnTitle}</strong></th>
                    );
                } else {
                    return (
                        <th className="no-sort"><strong className="title" key={key}>{customUI.columnTitle}</strong></th>
                    );
                }
            default:
                console.error("Unknown CustomUI custom UI type", customUI);
                break;
        }
    }

    return _items.map(renderHeader)
}

export default AdmissionList_Header_ManagerCustomUI;