import React, {useState} from 'react';
import useOnClickOutside from "use-onclickoutside";

import AdmissionList_SubFilter_DropdownItem from "./AdmissionList_SubFilter_DropdownItem";
import AdmissionList_SubFilter_CurrentSubfilter from "./AdmissionList_SubFilter_CurrentSubfilter";
import {subfilterVariables} from '../../../utils/subfilters';
import {useSelector} from "react-redux";
import AdmissionList_SubFilter_Wards from "./AdmissionList_SubFilter_Wards";
import AdmissionList_SubFilter_Array from "./AdmissionList_SubFilter_Array";
import AdmissionList_SubFilter_ArrayWithTitles from "./AdmissionList_SubFilter_ArrayWithTitles";

const AdmissionList_SubFilter = () => {
    const auth = useSelector(state => state.auth);
    const [isDropDownVisible, setDropDownVisible] = useState(false);
    const ref = React.useRef(null);
    useOnClickOutside(ref, () => hideDropDown());

    const toggleDropDown = () => setDropDownVisible(!isDropDownVisible);
    const hideDropDown = () => setDropDownVisible(false);

    const renderSubfilters = () => subfilterVariables.map(
        (item, key) => <AdmissionList_SubFilter_DropdownItem key={key} {...item} />
    );

    return (
        <ul className="table-buttons-holder dropdown" ref={ref}>
            <li className="table-buttons-item"><a href="#" className="btn btn-sm btn-dark-gray" onClick={toggleDropDown}>Filter</a></li>
            <div className={`subfilter-dropdown-menu tabs-dropdown-menu dropdown-menu-right${isDropDownVisible ? ' show' : ''} ${auth.selectedHospital.filters_className}`} aria-labelledby="dropdownMenuButton">
                {renderSubfilters()}
                <AdmissionList_SubFilter_Wards items={auth.selectedHospital.wards} handle={'WARD'}/>
                <hr />
                <AdmissionList_SubFilter_Array items={auth.selectedHospital.filters.claimCode} handle={'CLAIM_CODE'}/>
                <hr />
                {auth.selectedHospital.filters.fundingGroups && <AdmissionList_SubFilter_Array items={Object.keys(auth.selectedHospital.filters.fundingGroups)} handle={'FUNDING_GROUP'}/>}
                {auth.selectedHospital.filters.maternity && <><hr />
                    <AdmissionList_SubFilter_Array items={Object.keys(auth.selectedHospital.filters.maternity)} handle={'MATERNITY'}/>
                </>}
                </div>
            <AdmissionList_SubFilter_CurrentSubfilter />
        </ul>
    )
}

export default AdmissionList_SubFilter;