import React from "react";
import {useSelector} from "react-redux";

import ModalSelectDocumentSet from "./ModalSelectDocumentSet";
import ModalShowDocumentStatus from "./ModalShowDocumentStatus";
import ModalOecStatus from "./ModalOecStatus";
import ModalResendSms from "./ModalResendSms";
import ModalSendMessage from "./ModalSendMessage";
import ModalAdmissionTime from "./ModalAdmissionTime";
import ModalBackground from "./ModalBackground";
import ModalAdmissionTime_Customize from "./ModalAdmissionTime_Customize";
import ModalError from "./ModalError";
import ModalPresentingIllnessCodes from "./ModalPresentingIllnessCodes";
import ModalLifeHouseClinicalFlags from "./ModalLifeHouseClinicalFlags";
import ModalLifeHouseERFA from "./ModalLifeHouseERFA";
import ModalAdmissionRegDiff from "./ModalAdmissionRegDiff";
import ModalSendDocumentSetColumn from "./ModalSendDocumentSetColumn";
import ModalAdmissionDocumentPdf from "./ModalAdmissionDocumentPdf";
import ModalAdmissionUploadPdf from "./AdmissionUploadPdf/ModalAdmissionUploadPdf";
import ModalAdmissionViewPdf from "./AdmissionUploadPdf/ModalAdmissionViewPdf";
import ModalOecStatus_Proda from "./ModalOecStatus_Proda";
import ModalViewAdmissionMessage from "./ModalViewAdmissionMessage";
import ModalArchiveAdmissionDocuments from "./ModalArchiveAdmissionDocuments";
import ModalArchiveAdmissionDocuments2 from "./ModalArchiveAdmissionDocuments2";
import ModalAdmissionMessages from "./ModalAdmissionMessages";
import ModalAdmissionDocuments from "./ModalAdmissionDocuments";
import ModalAdmissionRfa from "./ModalAdmissionRfa";
import ModalSendAdmissionDocument from "./ModalSendAdmissionDocument";
import ModalLifehouseSelectClinicalTeam from "./ModalLifehouseSelectClinicalTeam";
import ModalLifehousePacRequired from "./ModalLifehousePacRequired";
import ModalLifehouseComments from "./ModalLifehouseComments";
import ModalComments from "./ModalComments";
import ModalArchiveLifehousePdf from "./ModalArchiveLifehousePdf";
import UploadPdf from "./CustomUI/UploadPdf";
import PreviewPdf from "./CustomUI/PreviewPdf";
import PreAdmissionsSet from "./CustomUI/PreAdmissionsSet";
import ModalArchivePatient from "./ModalArchivePatient";
import ModalConfirmAdmissionAction from "./ModalConfirmAdmissionAction";
import ModalAdmissionDocumentFiles from "./ModalAdmissionDocumentFiles";
import Send2 from "./CustomUI/Send2";

const ModalUI = () => {
    const {clinicalFlags, presentingIllnessCodes, sendMessage, selectDocumentSet, documentStatus, oecStatus, resendSms, admissionTime, admissionTimeCustomize, error, adm_reg_diff, lifehouse_erfa,admissionUploadPdf,admissionViewPdf, oecStatus_proda, sendDocumentSetColumn, admissionDocumentPdf, viewAdmissionMessage, archiveAdmissionDocuments, archiveAdmissionDocuments2, admissionMessages, admissionDocuments, admissionRfa, sendAdmissionDocuments, lifehouse_selectClinicalTeam, lifehouse_pacRequired, lifehouse_comments, comments, archiveLifehousePdf, customUI_uploadPdf, customUI_uploadPreviewPdf, customUI_preAdmissionSet, archivePatient, confirmAdmissionAction, admissionDocumentFiles, send2} = useSelector(state => state.modal);

    return <>
            {selectDocumentSet.show && <ModalSelectDocumentSet />}
            {documentStatus.show && <ModalShowDocumentStatus />}
            {oecStatus.show && <ModalOecStatus />}
            {resendSms.show && <ModalResendSms/>}
            {admissionTime.show && <ModalAdmissionTime />}
            {admissionTimeCustomize.show && <ModalAdmissionTime_Customize />}
            {presentingIllnessCodes.show && <ModalPresentingIllnessCodes />}
            {error.show && <ModalError />}
            {clinicalFlags.show && <ModalLifeHouseClinicalFlags /> }
            {lifehouse_erfa.show && <ModalLifeHouseERFA /> }
            {adm_reg_diff.show && <ModalAdmissionRegDiff /> }
            {sendDocumentSetColumn.show && <ModalSendDocumentSetColumn /> }
            {admissionDocumentPdf.show && <ModalAdmissionDocumentPdf /> }
            {admissionUploadPdf.show && <ModalAdmissionUploadPdf /> }
            {admissionViewPdf.show && <ModalAdmissionViewPdf /> }
            {oecStatus_proda.show && <ModalOecStatus_Proda /> }
            {archiveAdmissionDocuments.show && <ModalArchiveAdmissionDocuments /> }
            {archiveAdmissionDocuments2.show && <ModalArchiveAdmissionDocuments2 /> }
            {admissionMessages.show && <ModalAdmissionMessages /> }
            {admissionDocuments.show && <ModalAdmissionDocuments /> }
            {admissionRfa.show && <ModalAdmissionRfa /> }
            {viewAdmissionMessage.show && <ModalViewAdmissionMessage /> }
            {sendMessage.show && <ModalSendMessage />}
            {sendAdmissionDocuments.show && <ModalSendAdmissionDocument />}
            {lifehouse_selectClinicalTeam.show && <ModalLifehouseSelectClinicalTeam/>}
            {lifehouse_pacRequired.show && <ModalLifehousePacRequired />}
            {lifehouse_comments.show && <ModalLifehouseComments />}
            {comments.show && <ModalComments />}
            {archiveLifehousePdf.show && <ModalArchiveLifehousePdf />}
            { customUI_uploadPdf.show && <UploadPdf />}
            { customUI_uploadPreviewPdf.show && <PreviewPdf />}
            { customUI_preAdmissionSet.show && <PreAdmissionsSet />}
            { archivePatient.show && <ModalArchivePatient />}
            { confirmAdmissionAction.show && <ModalConfirmAdmissionAction />}
            { admissionDocumentFiles.show && <ModalAdmissionDocumentFiles />}

            { send2.show && <Send2 />}

        <ModalBackground />
        </>
}

export default ModalUI;