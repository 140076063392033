import GenerateApiIfcData from "./GenerateApiIfcData";
import {CUSTOM_LOGIC_DEMO} from "../../utils/customLogic";

export const webPasTerm = (hospital) => {
    switch (hospital.hospitalConfig.customLogic) {
        case "CUSTOM_LOGIC_MARYVALE":
        case CUSTOM_LOGIC_DEMO:
            return 'PAS';
        case "CUSTOM_LOGIC_LIFEHOUSE":
            return 'Meditech PAS';
        case "CUSTOM_LOGIC_ESPH":
            return 'ePAS';
        case "CUSTOM_LOGIC_HEALTHECARE":
        default:
            return 'WebPAS';
    }
}