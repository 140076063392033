import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import loadingGif from "../../../assets/images/loader.gif";

const AdmissionList_Item_CustomColumn = ({admission, managerCustomUi}) => {
    const dispatch = useDispatch();

    const reloadingCustomColumn = useSelector(state => state.main.reloadingCustomColumn);
    if (reloadingCustomColumn.includes(admission.id)) {
        return <td>
            <div className="d-flex flex-nowrap align-items-center">
                <button className={`btn btn-sm`}><img src={loadingGif} alt="Loading"/></button>
            </div>
        </td>;
    }

    const customUIStatus = admission.manager_customUI[managerCustomUi.id];
    // ==========================================================================

    const showModalSend = (event) => {
        dispatch({
            type: actionTypes.MODAL_SEND_DOCUMENT_SET_COLUMN_SHOW,
            admission: admission,
            managerCustomUi: managerCustomUi
        });
        event.preventDefault();
        event.stopPropagation();
    }
    const showModalViewDocument = (event) => {
        dispatch({
            type: actionTypes.MODAL_ADMISSION_DOCUMENT_PDF_SHOW,
            // admissionDocument: actionTypes.ADMISSION_DOCUMENT_COVID_SCREENING,
            admissionDocument: actionTypes.ADMISSION_DOCUMENT_MANAGER_CUSTOM_UI,
            managerCustomUi: managerCustomUi,
            customUIStatus: customUIStatus,
            admission: admission
        });

        event.preventDefault();
        event.stopPropagation();
    }
    if (!customUIStatus) {
        return null;
    }

    if (customUIStatus.status === 'NONE') {
        return (
            <td>
                <div className="d-flex flex-nowrap align-items-center" onClick={showModalSend}>
                    <button className="btn btn-sm btn-secondary" onClick={showModalSend}>{managerCustomUi.sendBtnTitle}</button>
                    {/*// ====================================================================================*/}
                </div>
            </td>
        );
    }
    console.log('[AdmissionList_Item_CustomColumn]', customUIStatus);

    const signedByManagerDataTip = () => {
        if (!customUIStatus.document || !customUIStatus.document.signedByManager) {
            return null;
        }
        return `signed by ${customUIStatus.document.signedByManager}`;
    }

    return (
        <td className={`${(customUIStatus.document && customUIStatus.document.signedByManager) ? 'red-border' : ''}`} data-tip={signedByManagerDataTip()}>
            <div className="d-flex flex-nowrap align-items-center" onClick={showModalViewDocument}>
                <button className={`btn btn-sm ${customUIStatus.buttonStyle}`} onClick={showModalViewDocument}>{customUIStatus.buttonText}</button>
            </div>
        </td>
    );
}

export default AdmissionList_Item_CustomColumn;