import React from "react";
import {useSelector} from "react-redux";
import {values} from 'lodash';
import ManagerCustomUI_DownloadPdf from "./ManagerCustomUI_DownloadPdf";
import ManagerCustomUI_UploadPdf from "./ManagerCustomUI_UploadPdf";
import ManagerCustomUI_PreAdmission from "./ManagerCustomUI_PreAdmission";
import ManagerCustomUI_SendRegMed from "./ManagerCustomUI_SendRegMed";

const ManagerCustomUI_MoreDrop = ({admission, onHide}) => {
    const managerCustomUI_unified = useSelector(state => state.auth.selectedHospital.managerCustomUI_unified);

    if (!managerCustomUI_unified) return ;

    const renderAllPositions = data => data.map(renderCustomUi);

    const renderCustomUi = (key, customUi) => {
        switch (customUi.type) {
            case 'TYPE_ADMISSION_LINK_DOWNLOAD_PDF':
                return <ManagerCustomUI_DownloadPdf admission={admission} managerCustomUi={customUi} onHide={onHide}/>
            case 'TYPE_ADMISSION_LIST_UPLOAD_PDF':
                return <ManagerCustomUI_UploadPdf admission={admission} managerCustomUi={customUi} onHide={onHide}/>
            case 'TYPE_ADMISSION_LIST_PRE_ADMISSION_INTEGRATION':
                return <ManagerCustomUI_PreAdmission admission={admission} managerCustomUi={customUi} onHide={onHide}/>
            case 'TYPE_ADMISSION_LIST_SEND_REG_MED':
                return <ManagerCustomUI_SendRegMed admission={admission} managerCustomUi={customUi} onHide={onHide} key={`reg-med-${key}`}/>
            default:
                console.error("Unknown CustomUI custom UI type", customUi.type);
                break;
        }
    }

    return values(managerCustomUI_unified.admission_list).map(renderAllPositions)
}

export default ManagerCustomUI_MoreDrop;